/* eslint-disable vue/return-in-computed-property */
<template>
  <v-app>
    <v-flex
      v-bind:class="{
        bgSvg: !$vuetify.theme.dark,
        bgSvgDark: $vuetify.theme.dark
      }"
    >
      <v-flex
        class="backDropper pt-6 pb-4 px-4"
        v-bind:style="{
          'color:rgb(255, 255, 255,0.5)': !$vuetify.theme.dark,
          'color:rgb(0,0,0)': $vuetify.theme.dark
        }"
      >
        <v-dialog
          v-model="reportOngoing"
          width="50%"
          :hide-overlay="false"
          persistent
          class="px-3"
        >
          <v-card class="text-center px-5 " style="height: 50px" tile>
            {{ progressMessages }}
            <v-progress-linear
              :value="progressValue"
              height="5"
              color="primary"
              class="px-n5"
            ></v-progress-linear>
          </v-card>
        </v-dialog>
        <v-dialog v-model="reportWindow" class="pa-10">
          <v-card min-height="70vh" class="pa-10" overflow>
            <v-btn
              dark
              @click="reportWindow = false"
              absolute
              top
              right
              class="buttonGlass red elevation-0 mt-10 mr-10"
              >close</v-btn
            >
            <div class="pa-5 tableData">
              <div class="text-center mb-6 warning--text">
                <h2><u>Report Details</u></h2>
              </div>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    readonly
                    outlined
                    v-model="form.name"
                    label="Submitted By"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    readonly
                    outlined
                    v-model="form.userDepartment"
                    label="Department"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    readonly
                    outlined
                    v-model="form.userDistrict"
                    label="District"
                  >
                  </v-text-field> </v-col
              ></v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    readonly
                    outlined
                    v-model="form.approvedBy"
                    label="Report Approved By"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    readonly
                    outlined
                    label="Report Created At"
                    :value="toDate(form.createdAt)"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    readonly
                    outlined
                    prepend-icon="fas fa-file"
                    v-model="form.reportType"
                    label="Report Type"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    readonly
                    outlined
                    v-model="form.dateOfSubmission"
                    prepend-icon="fas fa-calendar-alt"
                    type="text"
                    color="primary accent-3"
                    label="Date Of Report"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div
                v-if="
                  form.reportType == 'Monthly Report' &&
                    form.userNulm === 'SM&ID'
                "
              >
                <v-row>
                  <v-col cols="12" md="2">
                    <v-text-field
                      readonly
                      outlined
                      v-model="form.noOfMember"
                      label="Member Kal Zat"
                      type="text"
                      color="primary accent-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      readonly
                      outlined
                      color="primary accent-3"
                      label="Committee Neih Zat"
                      v-model="form.noOfCommittee"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      readonly
                      outlined
                      label="Commitee a member kal zat"
                      v-model="form.noOfMemberAttending"
                    ></v-text-field>
                  </v-col>

                  <v-col md="2" cols="12"
                    ><h4
                      class="grey--text mt-2"
                      :class="{
                        'text-start': $vuetify.breakpoint.mobile,
                        'text-end': !$vuetify.breakpoint.mobile
                      }"
                    >
                      Sum neih zat:
                    </h4></v-col
                  >
                  <v-col cols="12" md="2">
                    <v-text-field
                      readonly
                      outlined
                      label="Bank-a sum neih zat"
                      v-model="form.bankTotal"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      readonly
                      outlined
                      label="Account-a sum neih zat"
                      v-model="form.accountTotal"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      :label="
                        groupToFederation(`Group Member zinga pawisa puk`)
                      "
                      v-model="form.noOfMemberLend"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      :label="groupToFederation(`Group pawisa pukchhuah zat`)"
                      v-model="form.noLendAmount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      :label="
                        groupToFederation(
                          `Group pawisa pukchhuah rulhna dawn zat`
                        )
                      "
                      v-model="form.noLendPaidRec"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      :label="
                        groupToFederation(`Group meeting thawhlawm thawh zat`)
                      "
                      v-model="form.groupDonationAmount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      :label="groupToFederation(`Group pawisa hmanchhuah zat`)"
                      v-model="form.noGroupSpending"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" v-if="form.userDepartment === 'SHG'">
                    <v-text-field
                      outlined
                      label="Activities"
                      v-model="form.activitiesSelect"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" v-if="form.userDepartment !== 'SHG'">
                    <v-text-field
                      outlined
                      label="Federation Hmalakna"
                      v-model="form.federationHmalakna"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    v-if="form.activitiesSelect == 'Other(Specify)'"
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Activities(Others)"
                      v-model="form.activitiesSpecify"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-if="form.reportType == 'Meeting Report'" overflow>
                <v-row>
                  <v-col>
                    <v-textarea
                      readonly
                      v-model="form.meetingAgenda"
                      :rows="1"
                      align-center
                      filled
                      auto-grow
                      label="Meeting Agenda"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      readonly
                      :rows="1"
                      v-model="form.thurel"
                      filled
                      auto-grow
                      label="Thurel"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    form.userDepartment == 'ALF' || form.userDepartment == 'CLF'
                  "
                >
                  <v-text-field
                    name="Telpuitu"
                    v-model="form.telpuitu"
                    label="CO/URP meeting telpuitu hming"
                    outlined
                  ></v-text-field>
                </v-row>
                <div :v-show="form.attachment" class="">
                  <v-row>
                    <v-col
                      class="mb-3"
                      cols="12"
                      md="12"
                      v-for="(link, index) in form.attachment"
                      :key="index"
                    >
                      <h1>{{ index + 1 }}</h1>

                      <v-skeleton-loader
                        v-if="!imagecheck(link)"
                        max-height="50vh"
                        :max-width="calculateWidth"
                        class="mx-auto man"
                        :name="indexConvert(index)"
                        type="card-avatar, article"
                      ></v-skeleton-loader>

                      <v-img
                        v-if="!imagecheck(link)"
                        @error="imageError"
                        @loadstart="updateImageLoaded(index, false)"
                        @load="updateImageLoaded(index, true)"
                        :src="link"
                      ></v-img>
                      <div v-if="imagecheck(link)" class="text-center">
                        <v-icon color="red" size="30vh">
                          fas fa-exclamation
                        </v-icon>
                        <h4 class="mt-4">
                          This image seems to be missing from the server
                        </h4>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-if="form.co">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      readonly
                      label="SHG viltu CO hming"
                      outlined
                      v-model="form.co"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      readonly
                      label="SHG viltu URP hming"
                      outlined
                      v-model="form.urp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      readonly
                      label="RO viltu URP hming"
                      outlined
                      v-model="form.ro"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      readonly
                      outlined
                      v-model="form.coVisitDate"
                      class=""
                      label="CO in SHG rawn tlawh hun"
                      prepend-icon="fa fa-calendar-alt"
                      type="text"
                      color="primary accent-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      readonly
                      outlined
                      v-model="form.roVisitDate"
                      class=""
                      label="RO in SHG rawn tlawh hun"
                      prepend-icon="fa fa-calendar-alt"
                      type="text"
                      color="primary accent-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      readonly
                      outlined
                      v-model="form.urpVisitDate"
                      class=""
                      label="RO in SHG rawn tlawh hun"
                      prepend-icon="fa fa-calendar-alt"
                      type="text"
                      color="primary accent-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-flex
                v-if="
                  form.userNulm === 'SUH' &&
                    form.userDepartment === 'SMA' &&
                    form.reportType == 'Monthly Report'
                "
              >
                <v-row>
                  <v-col cols="12" class="pt-0 mt-0">
                    <h3 class="text-center">Monthly Report</h3>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      readonly
                      label="Name of Shelter"
                      dense
                      outlined
                      v-model="form.name"
                    ></v-text-field> </v-col
                  ><v-col cols="12" md="6">
                    <v-text-field
                      readonly
                      label="Name of SMA"
                      dense
                      outlined
                      v-model="form.sma"
                    ></v-text-field>
                  </v-col> </v-row
                ><v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      readonly
                      label="District of SMA"
                      dense
                      outlined
                      v-model="form.userDistrict"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="left--text">
                    <h4 style="text-align:left; !important">
                      DAY-NULM hmanga tanpuina dawnna:
                    </h4>
                    <v-checkbox
                      dense
                      readonly
                      label="New Construction"
                      value="New Construction"
                      v-model="form.tanpuinaDawnna"
                    ></v-checkbox>
                    <v-checkbox
                      readonly
                      dense
                      label="Refurbishment"
                      value="Refurbishment"
                      v-model="form.tanpuinaDawnna"
                    ></v-checkbox>
                    <v-checkbox
                      readonly
                      dense
                      label="O&M"
                      value="O&M"
                      v-model="form.tanpuinaDawnna"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-flex
                  class="mx-0 my-0 px-0 py-0"
                  v-if="reportCheckOnM || reportCheckRefurbOnly"
                >
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        readonly
                        label="Number of inmates during the month"
                        dense
                        outlined
                        v-model="form.noOfMember"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        readonly
                        label="Number of inmates expire during the month"
                        dense
                        outlined
                        v-model="form.noOfMemberDeath"
                      ></v-text-field
                    ></v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Amount of SMC meeting for the month"
                        dense
                        outlined
                        readonly
                        v-model="form.noOfCommittee"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        readonly
                        label="Number of staff"
                        dense
                        outlined
                        v-model="form.noOfStaff"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        readonly
                        label="Number of Complaint/Greivances received for the month"
                        dense
                        outlined
                        v-model="form.noOfComplaint"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Number of Complaint/Greivances solved at the city level"
                        dense
                        outlined
                        v-model="form.noOfComplaintResolved"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="DAY-NULM atanga facility dawn tawh"
                        dense
                        outlined
                        readonly
                        v-model="form.noOfNulmFacility"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Report pek thla huam chhunga DAY-NULM atanga facility dawn thar zat"
                        dense
                        outlined
                        readonly
                        v-model="form.noOfNulmFacilitySubmittedMonth"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-flex>
                <v-flex class="mx-0 my-0 py-0 px-0" v-if="reportCheckOnM">
                  <v-row>
                    <!-- <v-col cols="12" align-self="center" class="pb-4">
                      <h3 class="">
                        Overall Financial Report:DAY-NULM atanga O&M fund dawn
                        tawh zawng zawng:
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        readonly
                        outlined
                        dense
                        :value="financialYearRep"
                        class=""
                        label="Financial Year"
                        prepend-icon="fa fa-calendar-alt"
                        type="text"
                        color="primary accent-3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        readonly
                        label="Amount"
                        dense
                        outlined
                        :value="fundRec(userInfo)"
                      ></v-text-field
                    ></v-col> -->
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <h3>
                        Monthly Financial Report (for O&M fund supported SMA)
                      </h3>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Number of funds recieved from O&M during the month"
                        dense
                        outlined
                        readonly
                        v-model="form.noOfOnM_FundRecieved"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Shelter maintenance cost"
                        dense
                        outlined
                        readonly
                        v-model="form.shelterMaintenance"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Servicing cost"
                        dense
                        outlined
                        readonly
                        v-model="form.servicingCost"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Food for Category I homeless"
                        dense
                        outlined
                        readonly
                        v-model="form.catIhomeless"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Staff Salary"
                        dense
                        outlined
                        readonly
                        v-model="form.staffSalary"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        readonly
                        label="Expenditure for the month"
                        dense
                        outlined
                        v-model="expenditureRep"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-flex>
              <div class="text-center py-3" v-if="checkReportApproval()">
                <v-btn
                  @click="approveReport"
                  align-content="center"
                  rounded
                  dark
                  class="primary"
                  outlined
                  >Approve</v-btn
                >
              </div>
            </div>
            <div class="text-center mt-6">
              <v-btn
                dark
                class="primary buttonGlass"
                @click="generateReport(form)"
              >
                Print
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
        <v-card elevation="6" background-color="" class="pt-0 mt-1">
          <v-tabs
            v-model="tab"
            centered
            background-opacity="100"
            icons-and-text
            dense
            :style="{
              'background-color:#e5f3fe': $vuetify.theme.dark,
              'background-color:red': !$vuetify.theme.dark
            }"
            class="mt-n3 pt-0"
          >
            <v-tab> Report List </v-tab>
            <v-tab v-if="!['SMMU', 'CMMU'].includes(userInfo.nulm)">
              New Report
            </v-tab>
            <v-tabs-items v-model="tab" style="">
              <v-tab-item>
                <v-card class="mx-4 py-3" elevation="0" min-height="70vh">
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form ref="reportForm" class="mx-5 text-center mt-3">
                      <v-row>
                        <v-col cols="12" md="3" class="my-0 mx-0">
                          <validation-provider
                            name="Report Submit-tu department"
                            v-slot="{ errors }"
                            rules="ngaiNgeiNgei"
                          >
                            <v-select
                              dense
                              prepend-icon="fas fa-file-alt"
                              outlined
                              :items="retrieveReports"
                              label=" Department"
                              :errorMessages="errors"
                              v-model="reportTypeNulmRetrieveList"
                              :readonly="
                                ['SHG', 'SMA', 'ESTP', 'SEP', 'SUSV'].includes(
                                  userInfo.department
                                )
                              "
                            ></v-select>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" md="3" class="my-0 mx-0">
                          <validation-provider
                            name="Report submittu District"
                            v-slot="{ errors }"
                            rules="ngaiNgeiNgei"
                          >
                            <v-select
                              dense
                              prepend-icon="fas fa-archway"
                              outlined
                              :items="District"
                              label="District"
                              :errorMessages="errors"
                              v-model="reportRetrieveDistrict"
                              :readonly="!['SMMU'].includes(userInfo.nulm)"
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" md="3" class="my-0 mx-0">
                          <v-text-field
                            dense
                            prepend-icon="fas fa-file-alt"
                            outlined
                            hint="Dah ruah chuan report zawng zawng a zawh theih"
                            label="Hming/Group Code"
                            class="text-capitalize"
                            v-model="reportTypeSearchRetrieveList"
                            :readonly="
                              ['SHG', 'SMA', 'ESTP', 'SEP', 'SUSV'].includes(
                                userDepartment
                              )
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col
                          v-if="reportTypeSearchRetrieveList"
                          cols="12"
                          md="3"
                          class="my-0 mx-0"
                        >
                          <validation-provider
                            name="Report Submit-tu Hming emaw Group Code"
                            v-slot="{ errors }"
                            rules="ngaiNgeiNgei"
                          >
                            <v-select
                              dense
                              prepend-icon="fas fa-file-alt"
                              outlined
                              :items="['Name', 'Group Code']"
                              label="Zawn Dan"
                              :errorMessages="errors"
                              v-model="reportTypeSearchByRetrieveList"
                              :readonly="
                                ['SHG', 'SMA', 'ESTP', 'SEP', 'SUSV'].includes(
                                  userDepartment
                                )
                              "
                            ></v-select>
                          </validation-provider>
                        </v-col>

                        <v-col
                          class="my-0 mx-0"
                          cols="12"
                          md="3"
                          v-if="
                            !['SHG', 'SMA', 'ESTP', 'SEP', 'SUSV'].includes(
                              userDepartment
                            )
                          "
                        >
                          <validation-provider
                            v-if="!reportTypeSearchRetrieveList"
                            name="Report Approve tawhtu"
                            v-slot="{ errors }"
                            rules="ngaiNgeiNgei"
                          >
                            <v-select
                              dense
                              outlined
                              :items="approver"
                              :error-messages="errors"
                              label="Report Approve tawhtu"
                              prepend-icon="fas fa-check"
                              v-model="approverInfo"
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col
                          class="my-0 mx-0 py-0"
                          :class="{
                            'text-center mb-4': $vuetify.breakpoint.mobile,
                            'text-end': !$vuetify.breakpoint.mobile
                          }"
                          ><v-btn
                            v-if="
                              !['SHG', 'SMA', 'ESTP', 'SEP', 'SUSV'].includes(
                                userDepartment
                              )
                            "
                            :disabled="invalid"
                            dark
                            class="primary ma-0 mb-5 mx-5 buttonGlass"
                            outlined
                            v-on:click="retrieveListOfReports"
                            >Retrieve</v-btn
                          ></v-col
                        >
                      </v-row>
                    </v-form>
                  </validation-observer>
                  <div class="tableData" v-if="userReportList">
                    <v-data-table
                      class="row-pointer accent ma-1 pa-0 row-pointer"
                      :items="userReportList"
                      @click:row="displayReport"
                      :headers="headersReportList"
                      :items-per-page="5"
                      :calculate-widths="true"
                      :disable-pagination="userReportList.length < 6"
                      :hide-default-footer="userReportList.length < 6"
                      :height="300"
                      :loading="loading"
                      loading-text="Loading... Please wait"
                    >
                      <template v-slot:[`item.createdAt`]="props">
                        <span>{{
                          retrieveTimestamp(props.item.createdAt)
                        }}</span>
                      </template>
                    </v-data-table>
                  </div>

                  <div class="text-center mt-5" v-if="userReportList">
                    <v-btn
                      class="buttonGlass"
                      v-if="userReportList.length > 4 && nextBtn"
                      align-center
                      color="primary"
                      dark
                      @click="retrieveListOfReportsNext"
                      >next</v-btn
                    >
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="mx-4 py-3" elevation="0" max-height="">
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form
                      ref="reportForm"
                      class="mx-5 text-center"
                      style="min-height: 68vh"
                    >
                      <v-row>
                        <v-col>
                          <validation-provider
                            v-slot="{ errors }"
                            name="Report type"
                            rules="ngaiNgeiNgei"
                          >
                            <v-select
                              dense
                              outlined
                              :error-messages="errors"
                              prepend-icon="fas fa-file"
                              :items="reportListType"
                              v-model="reportType"
                              label="Report Type"
                            >
                            </v-select>
                          </validation-provider>
                        </v-col>
                        <v-col>
                          <v-menu
                            :close-delay="2000"
                            ref="calendar"
                            v-model="calendar"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Date"
                                rules="ngaiNgeiNgei"
                              >
                                <v-text-field
                                  readonly
                                  outlined
                                  dense
                                  v-model="dateOfSubmission"
                                  :error-messages="errors"
                                  class=""
                                  v-bind:label="reportTypeLabel"
                                  prepend-icon="fas fa-calendar-alt"
                                  type="text"
                                  color="primary accent-3"
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field> </validation-provider
                            ></template>

                            <v-date-picker
                              color="primary"
                              v-model="dos"
                              min="1950-01-01"
                              :type="datePickerType"
                              @input="calendar = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          reportType == 'Monthly Report' && userNulm === 'SM&ID'
                        "
                      >
                        <v-row>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Member zat"
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                dense
                                :error-messages="errors"
                                label="Member zat"
                                v-model="noOfMember"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Commitee zat"
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                :error-messages="errors"
                                dense
                                label="Committee Neih Zat"
                                v-model="noOfCommittee"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Commitee a member kal zat"
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                dense
                                :error-messages="errors"
                                label="Commitee a member kal zat"
                                v-model="noOfMemberAttending"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="2"
                            ><h4 class="grey--text mt-2">
                              Sum neih zat:
                            </h4></v-col
                          >

                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Bank sum neih zat"
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                dense
                                :error-messages="errors"
                                label="Bank-a sum neih zat"
                                v-model="bankTotal"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              name="Accounta sum neih zat"
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                dense
                                :error-messages="errors"
                                label="Account-a sum neih zat"
                                v-model="accountTotal"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <!-- <v-col cols="2"><h4 class="grey--text mt-2">Internal Lending</h4></v-col> -->
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="
                                groupToFederation(
                                  `Group Member zinga pawisa puk mek`
                                )
                              "
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                dense
                                :error-messages="errors"
                                :label="
                                  groupToFederation(
                                    `Group Member zinga pawisa puk`
                                  )
                                "
                                v-model="noOfMemberLend"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="
                                groupToFederation(`Group pawisa pukchhuah zat`)
                              "
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                dense
                                :error-messages="errors"
                                :label="
                                  groupToFederation(
                                    `Group pawisa pukchhuah zat`
                                  )
                                "
                                v-model="noLendAmount"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="
                                groupToFederation(
                                  `Group pawisa pukchhuah rulhna dawn zat`
                                )
                              "
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                dense
                                :error-messages="errors"
                                :label="
                                  groupToFederation(
                                    `Group pawisa pukchhuah rulhna dawn zat`
                                  )
                                "
                                v-model="noLendPaidRec"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="
                                groupToFederation(
                                  `Group meeting thawhlawm thawh zat`
                                )
                              "
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                dense
                                :error-messages="errors"
                                :label="
                                  groupToFederation(
                                    `Group meeting thawhlawm thawh zat`
                                  )
                                "
                                v-model="groupDonationAmount"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="
                                groupToFederation(`Group pawisa hmanchhuah zat`)
                              "
                              rules="ngaiNgeiNgei|number"
                            >
                              <v-text-field
                                outlined
                                dense
                                :error-messages="errors"
                                :label="
                                  groupToFederation(
                                    `Group pawisa hmanchhuah zat`
                                  )
                                "
                                v-model="noGroupSpending"
                              ></v-text-field
                            ></validation-provider>
                          </v-col>
                          <v-col v-if="userDepartment === 'SHG'">
                            <validation-provider
                              v-slot="{ errors }"
                              name="Activities"
                              rules="ngaiNgeiNgei"
                            >
                              <v-select
                                outlined
                                dense
                                :error-messages="errors"
                                :items="activities"
                                label="Activities"
                                v-model="activitiesSelect"
                              ></v-select
                            ></validation-provider>
                          </v-col>
                          <v-col v-if="userDepartment !== 'SHG'">
                            <validation-provider
                              name="Federation Hmalakna"
                              rules="ngaiNgeiNgei"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :errorMessages="errors"
                                outlined
                                dense
                                label="Federation Hmalakna"
                                v-model="federationHmalakna"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col v-if="activitiesSelect == 'Other(Specify)'">
                            <validation-provider
                              name="Activities clarification"
                              rules="ngaiNgeiNgei"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                outlined
                                dense
                                :errorMessages="errors"
                                label="Activities(Others)"
                                v-model="activitiesSpecify"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-row>
                      <div v-if="reportType == 'Meeting Report'">
                        <v-row>
                          <v-col>
                            <validation-provider
                              name="Agenda"
                              rules="ngaiNgeiNgei"
                              v-slot="{ errors }"
                            >
                              <v-textarea
                                v-model="meetingAgenda"
                                filled
                                align-center
                                auto-grow
                                :error-messages="errors"
                                label="Meeting Agenda"
                                outlined
                              ></v-textarea> </validation-provider
                          ></v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <validation-provider
                              name="Thurel"
                              rules="ngaiNgeiNgei"
                              v-slot="{ errors }"
                            >
                              <v-textarea
                                v-model="thurel"
                                filled
                                auto-grow
                                :error-messages="errors"
                                label="Thurel"
                                outlined
                              ></v-textarea>
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row
                          v-if="
                            userDepartment == 'ALF' || userDepartment == 'CLF'
                          "
                        >
                          <v-col>
                            <validation-provider
                              name="Telpuitu"
                              rules="ngaiNgeiNgei"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :errorMessages="errors"
                                name="Telpuitu"
                                v-model="telpuitu"
                                label="CO/URP meeting telpuitu hming"
                                outlined
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </div>
                      <v-row
                        v-if="
                          reportType ===
                            'SHG enkawltu CO, URP leh RO te tlawh dan' &&
                            userNulm === 'SM&ID'
                        "
                      >
                        <v-row>
                          <v-col cols="">
                            <validation-provider
                              v-slot="{ errors }"
                              name="SHG viltu CO hming"
                              rules="ngaiNgeiNgei"
                            >
                              <v-text-field
                                :errorMessages="errors"
                                label="SHG viltu CO hming"
                                outlined
                                v-model="co"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="">
                            <validation-provider
                              v-slot="{ errors }"
                              name="SHG viltu URP hming"
                              rules="ngaiNgeiNgei"
                            >
                              <v-text-field
                                :errorMessages="errors"
                                label="SHG viltu URP hming"
                                outlined
                                v-model="urp"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="">
                            <validation-provider
                              v-slot="{ errors }"
                              name="RO viltu URP hming"
                              rules="ngaiNgeiNgei"
                            >
                              <v-text-field
                                :errorMessages="errors"
                                label="RO viltu URP hming"
                                outlined
                                v-model="ro"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-menu
                              ref="calendar"
                              v-model="co_date_menu"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Date"
                                  rules="ngaiNgeiNgei"
                                >
                                  <v-text-field
                                    readonly
                                    outlined
                                    dense
                                    v-model="coVisitDate"
                                    :error-messages="errors"
                                    class=""
                                    label="CO in SHG rawn tlawh hun"
                                    prepend-icon="fa fa-calendar-alt"
                                    type="text"
                                    color="primary accent-3"
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field> </validation-provider
                              ></template>

                              <v-date-picker
                                color="primary"
                                v-model="co_date"
                                min="1950-01-01"
                                @input="co_date_menu = false"
                              ></v-date-picker> </v-menu
                          ></v-col>
                          <v-col>
                            <v-menu
                              ref="calendar"
                              v-model="urp_date_menu"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Date"
                                  rules="ngaiNgeiNgei"
                                >
                                  <v-text-field
                                    readonly
                                    outlined
                                    dense
                                    v-model="urpVisitDate"
                                    :error-messages="errors"
                                    class=""
                                    label="URP in SHG rawn tlawh hun"
                                    prepend-icon="fas fa-calendar-alt"
                                    type="text"
                                    color="primary accent-3"
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field> </validation-provider
                              ></template>

                              <v-date-picker
                                color="primary"
                                v-model="urp_date"
                                min="1950-01-01"
                                @input="urp_date_menu = false"
                              ></v-date-picker> </v-menu
                          ></v-col>
                          <v-col>
                            <v-menu
                              ref="calendar"
                              v-model="ro_date_menu"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Date"
                                  rules="ngaiNgeiNgei"
                                >
                                  <v-text-field
                                    readonly
                                    outlined
                                    dense
                                    v-model="roVisitDate"
                                    :error-messages="errors"
                                    class=""
                                    label="RO in SHG rawn tlawh hun"
                                    prepend-icon="fas fa-calendar-alt"
                                    type="text"
                                    color="primary accent-3"
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field> </validation-provider
                              ></template>

                              <v-date-picker
                                color="primary"
                                v-model="ro_date"
                                min="1950-01-01"
                                @input="ro_date_menu = false"
                              ></v-date-picker> </v-menu
                          ></v-col> </v-row
                      ></v-row>
                      <v-flex
                        v-if="
                          userDepartment === 'SMA' &&
                            reportType == 'Monthly Report'
                        "
                      >
                        <v-row>
                          <v-col cols="12" class="pt-0 mt-0">
                            <h3 class="text-center">Monthly Report</h3>
                          </v-col>
                          <v-col>
                            <validation-provider
                              name="Shelter Hming"
                              rules="ngaiNgeiNgei"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                label="Name of Shelter"
                                dense
                                outlined
                                :errorMessages="errors"
                                v-model="shelterName"
                              ></v-text-field> </validation-provider
                          ></v-col>
                          <v-col>
                            <validation-provider
                              name="Shelter Management Agency (SMA) Hming"
                              rules="ngaiNgeiNgei"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                label="Name of SMA"
                                dense
                                outlined
                                :errorMessages="errors"
                                v-model="sma"
                              ></v-text-field> </validation-provider
                          ></v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <validation-provider
                              name="District"
                              rules="ngaiNgeiNgei"
                              v-slot="{ errors }"
                            >
                              <v-select
                                label="District of SMA"
                                dense
                                outlined
                                :errorMessages="errors"
                                v-model="userDistrict"
                                :items="District"
                              ></v-select> </validation-provider
                          ></v-col>
                          <v-col class="left--text">
                            <h4 style="text-align:left; !important">
                              DAY-NULM hmanga tanpuina dawnna:
                            </h4>
                            <v-checkbox
                              dense
                              label="New Construction"
                              value="New Construction"
                              v-model="tanpuinaDawnna"
                            ></v-checkbox>
                            <v-checkbox
                              dense
                              label="Refurbishment"
                              value="Refurbishment"
                              v-model="tanpuinaDawnna"
                            ></v-checkbox>
                            <v-checkbox
                              dense
                              label="O&M"
                              value="O&M"
                              v-model="tanpuinaDawnna"
                            ></v-checkbox>
                          </v-col>
                        </v-row>

                        <v-flex class="mx-0 my-0 px-0 py-0" v-if="showTd > 0">
                          <v-row>
                            <v-col>
                              <validation-provider
                                name="SUH-ah report submit thla a awm zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Number of inmates during the month"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="noOfMember"
                                ></v-text-field> </validation-provider
                            ></v-col>
                            <v-col>
                              <validation-provider
                                name="SUH-ah report submit thla a thi zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Number of inmates expire during the month"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="noOfMemberDeath"
                                ></v-text-field> </validation-provider
                            ></v-col>
                          </v-row>
                          <v-row
                            ><v-col>
                              <validation-provider
                                name="SUH-ah report submit thla a SMC meeting zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Amount of SMC meeting for the month"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="noOfCommittee"
                                ></v-text-field> </validation-provider></v-col
                            ><v-col>
                              <validation-provider
                                name="Staff awm zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Number of staff"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="noOfStaff"
                                ></v-text-field> </validation-provider></v-col
                          ></v-row>

                          <v-row>
                            <v-col>
                              <validation-provider
                                name="Michengte atanga complaint dawn zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Number of Complaint/Greivances received for the month"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="noOfComplaint"
                                ></v-text-field> </validation-provider
                            ></v-col>
                            <v-col>
                              <validation-provider
                                name="Michengte atanga complaint dawn chinfel zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Number of Complaint/Greivances solved at the city level for the month"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="noOfComplaintResolved"
                                ></v-text-field> </validation-provider
                            ></v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <validation-provider
                                name="DAY-NULM atanga facility dawn tawh"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="DAY-NULM atanga facility dawn tawh"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="noOfNulmFacility"
                                ></v-text-field> </validation-provider
                            ></v-col>
                            <v-col>
                              <validation-provider
                                name="Report pek thla huam chhunga DAY-NULM atanga facility dawn thar zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Report pek thla huam chhunga DAY-NULM atanga facility dawn thar zat"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="noOfNulmFacilitySubmittedMonth"
                                ></v-text-field> </validation-provider
                            ></v-col>
                          </v-row>
                        </v-flex>
                        <v-flex class="mx-0 my-0 py-0 px-0" v-if="showTd === 2">
                          <v-row>
                            <v-col cols="12" align-self="center" class="pb-4">
                              <h3 class="">
                                Overall Financial Report:DAY-NULM atanga O&M
                                fund dawn tawh zawng zawng:
                              </h3>
                            </v-col>

                            <v-col>
                              <v-text-field
                                readonly
                                outlined
                                dense
                                :value="financialYear"
                                class=""
                                label="Financial Year"
                                prepend-icon="fas fa-calendar-alt"
                                type="text"
                                color="primary accent-3"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                readonly
                                label="Amount"
                                dense
                                outlined
                                :value="fundRec(userInfo)"
                              ></v-text-field
                            ></v-col>

                            <v-col
                              class="border"
                              v-if="financialYearItems.length > 0"
                              ><v-data-table
                                :disable-pagination="true"
                                :hide-default-footer="true"
                                :items="financialYearItems"
                                :headers="financialYearHeader"
                              ></v-data-table
                            ></v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <h3>
                                Monthly Financial Report (for O&M fund supported
                                SMA)
                              </h3>
                            </v-col>

                            <v-col>
                              <validation-provider
                                name="O&M atanga fund dawn zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Number of funds recieved from O&M during the month"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="noOfOnMfunds"
                                ></v-text-field> </validation-provider
                            ></v-col>
                            <v-col>
                              <validation-provider
                                name="Shelter maintain nana pawisa hman zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Shelter maintenance cost"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="shelterMaintenance"
                                ></v-text-field> </validation-provider
                            ></v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <validation-provider
                                name="Service nana pawisa hman zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Servicing cost"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="servicingCost"
                                ></v-text-field> </validation-provider
                            ></v-col>
                            <v-col>
                              <validation-provider
                                name="Category I homeless tana chaw man "
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Food for Category I homeless"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="catIhomeless"
                                ></v-text-field> </validation-provider
                            ></v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <validation-provider
                                name="Staff ho hlawh zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Staff Salary"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="staffSalary"
                                ></v-text-field> </validation-provider
                            ></v-col>
                            <v-col>
                              <validation-provider
                                name="Pawisa a thla atana hman zat"
                                rules="ngaiNgeiNgei|number"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  readonly
                                  label="Expenditure for the month"
                                  dense
                                  outlined
                                  :errorMessages="errors"
                                  v-model="expenditure"
                                ></v-text-field> </validation-provider
                            ></v-col>
                          </v-row>
                        </v-flex>
                      </v-flex>
                      <v-row justify="center" align="center">
                        <v-col v-if="reportType == 'Meeting Report'">
                          <div class=" ma-0 pa-0 ">
                            <v-file-input
                              counter
                              multiple
                              show-size
                              label="File Upload Na"
                              prepend-icon="fas fa-paperclip"
                              outline
                              dense
                              class="mx-4 hoverer pa-3"
                              v-model="fileUpload"
                            ></v-file-input>
                          </div>
                        </v-col>
                        <v-col justify-center>
                          <v-btn
                            :readonly="invalid"
                            dark
                            class="primary mt-1 mb-4 buttonGlass"
                            outlined
                            persistent-hint
                            v-on:click="submitReport()"
                            >Submit</v-btn
                          ></v-col
                        >
                      </v-row>
                    </v-form>
                  </validation-observer>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs></v-card
        >
      </v-flex>
    </v-flex>
  </v-app>
</template>
<script>
import { jsPDF } from 'jspdf'
import { mapState } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import { required, numeric } from 'vee-validate/dist/rules'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'
setInteractionMode('lazy')
extend('ngaiNgeiNgei', {
  ...required,
  message: '{_field_} a ruak theilo'
})

extend('number', {
  ...numeric,
  message: 'Number chauh thun tur'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      reportCreatedAt: null,
      imageErr: [],
      loading: false,
      nextBtn: false,
      showTd: 0,
      checkLoad: [],
      fileProg: [],
      fileUpload: [],
      progress: 0,
      arr: [],
      form: {},
      approverInfo: '',
      reportApprovalId: '',
      headersReportList: [
        { text: 'Date of Submission', value: 'createdAt' },
        { text: 'Date of Report', value: 'dateOfSubmission' },
        { text: 'Type', value: 'reportType' },
        { text: 'Approved By', value: 'approvedBy' }
      ],
      userReportList: [],

      userReportListDetails: [],
      reportRetrieveDistrict: null,
      reportTypeNulmRetrieveList: null,
      reportTypeSearchRetrieveList: '',
      reportTypeSearchByRetrieveList: '',
      progressMessages: '',
      progressValue: '0',
      reportOngoing: false,
      calendar: null,
      calendarReport: null,
      userNulm: '',
      userDepartment: '',
      userDistrict: '',
      reportWindow: false,
      District: [
        'Aizawl',
        'Kolasib',
        'Lawngtlai',
        'Lunglei',
        'Mamit',
        'Saiha ',
        'Serchhip',
        'Champhai',
        'Hnahthial',
        'Khawzawl',
        'Saitual'
      ],
      shelterName: null,
      sma: null,
      reportType: null,
      tab: null,
      dos: null,
      dosReportList: null,
      noOfCommittee: null,
      noOfMember: null,
      noOfStaff: null,
      noOfMemberDeath: null,
      noOfComplaint: null,
      noOfComplaintResolved: null,
      noOfMemberAttending: null,
      bankTotal: null,
      accountTotal: null,
      noOfMemberLend: null,
      noLendAmount: null,
      noLendPaidRec: null,
      groupDonationAmount: null,
      noGroupSpending: null,
      groupActivities: null,
      activities: [
        'Agriculture',
        'Apparels',
        'Automotive',
        'Bamboo Fabrication',
        'Beauty and Wellness',
        'BF SI',
        'Capital Goods',
        'Chemical and Petrochemicals',
        'Construction',
        'Domestic Workers',
        'Electrical',
        'Electronics and IT Hardware',
        'Fire and Safety Engineering',
        'Food Processing',
        'Furniture and Furnishing',
        'Gems and Jewelry',
        'Green Jobs',
        'Handicrafts',
        'Healthcare and Nutrition',
        'Infrastructure Equipment',
        'Iron and Steel',
        'IT-ITES',
        'Leather',
        'Life Sciences',
        'Logistics',
        'Manufacturing',
        'Media and Entertainment',
        'Other(Specify)',
        'Power',
        'Printing',
        'Renewable Energy',
        'Retail',
        'Rubber',
        'Sanitation',
        'Security',
        'Soft Skills, Entrepreneurship and Counseling',
        'Solid Waste Management',
        'Telecom',
        'Textile and Handloom',
        'Tourism and Hospitality'
      ],
      activitiesSelect: null,
      activitiesSpecify: null,
      federationHmalakna: null,
      meetingAgenda: null,
      thurel: null,
      telpuitu: null,
      co: null,
      urp: null,
      ro: null,
      co_date: null,
      co_date_menu: false,
      urp_date: null,
      urp_date_menu: null,
      ro_date: null,
      ro_date_menu: null,
      noOfOnMfunds: null,
      shelterMaintenance: null,
      servicingCost: null,
      catIhomeless: null,
      staffSalary: null,
      tanpuinaDawnna: [],
      noOfNulmFacility: null,
      noOfNulmFacilitySubmittedMonth: null,
      lastReport: null,
      searchBoolRep: true,
      colRep: null,
      colRepApp: null,

      finalProg: 0,
      financialYearHeader: [
        {
          text: 'Year',
          value: 'year'
        },
        {
          text: 'Amount',
          value: 'amount'
        }
      ]
    }
  },
  methods: {
    async generateReport(userReport) {
      let finalParse = this.reportParse(userReport)

      for (let key in finalParse) {
        if (!finalParse[key]) {
          delete finalParse[key]
        }
      }

      const doc = new jsPDF()
      let vLength = 6.4925
      let initTop = 4
      for (let keys in finalParse) {
        let key = doc.splitTextToSize(keys, 58)
        let value = doc.splitTextToSize(userReport[keys], 120)
        if ((key.length == value.length) == 1) {
          doc.text(vLength * 3, initTop * vLength, key)

          try {
            doc.text(vLength * 12, initTop * vLength, userReport[keys])
          } catch (err) {
            err
          }
          initTop = initTop + 2
          if (initTop >= 42) {
            doc.addPage()
            initTop = 4
          }
        } else {
          if (value.length > key.length) {
            doc.text(vLength * 3, initTop * vLength, key)
            if (initTop + value.length > 42) {
              do {
                let valueOfSplice = value.splice(0, 42 - initTop)
                doc.text(vLength * 12, initTop * vLength, valueOfSplice)

                if (valueOfSplice.length + initTop > 35) {
                  initTop = 4
                  doc.addPage()
                } else {
                  initTop = initTop + valueOfSplice.length + 1
                }
              } while (value.length)
            } else {
              doc.text(vLength * 12, initTop * vLength, value)
              initTop = initTop + value.length
            }
          } else if (key.length > value.length) {
            if (key.length + initTop > 42) {
              initTop = 4
              doc.addPage()
              doc.text(vLength * 3, initTop * vLength, key)
              doc.text(vLength * 12, initTop * vLength, value)
              initTop = initTop + key.length + 1
            } else {
              doc.text(vLength * 3, initTop * vLength, key)
              doc.text(vLength * 12, initTop * vLength, value)
              initTop = initTop + key.length + 1
            }
          }
        }
      }

      doc.save(
        `${userReport.Hming} ${userReport['Report Type']} for ${userReport['Report for the date/month']}`
      )
    },
    reportParse(form) {
      let fbNow = String(firebase.firestore.Timestamp.now().toDate()).replace(
        'GMT+0530 (India Standard Time)',
        ''
      )
      let formRet = form
      let keyPair = {
        name: 'Hming',
        reportType: 'Report Type',
        userNulm: 'NULM',
        userDepartment: 'Department',
        userDistrict: 'District',
        approvedBy: 'Approve Tawhtu',
        telpuitu: 'Telpuitu',
        dateOfSubmission: 'Report for the date/month',
        meetingAgenda: 'Agenda',
        thurel: 'Thurel',
        noOfMember: 'Inmates awm zat',
        groupDonationAmount: 'Meeting thawhlawm thawh zat',
        noOfCommittee: 'Report thla-a meeting neih zat',
        activitiesSelect: 'Activities',
        activitiesSpecify: 'Activities (Others)',
        noGroupSpending: 'Pawisa Hmanchhuah Zat',
        noOfMemberAttending: 'Commitee a member kal',
        noOfMemberLend: 'Group Member zinga pawisa puk',
        bankTotal: 'Bank-a sum neih zat',
        noLendPaidRec: 'Group pawisa pukchhuah rulhna dawn zat',
        noLendAmount: 'Group pawisa pukchhuah zat',
        accountTotal: 'Account-a sum neih zat',
        ro: 'Tlawhtu RO',
        urp: 'Tlawhtu URP',
        co: 'Tlawhtu CO',
        roVisitDate: 'RO-in a tlawhni',
        urpVisitDate: 'URP-in a tlawhni',
        coVisitDate: 'CO-in a tlawhni',
        tanpuinaDawnna: 'Tanpuina Dawnna',
        shelterName: 'Shelter Hming',
        noOfComplaint: 'Complaint awm zat',
        sma: 'SMA Hming',
        noOfStaff: 'Staff awm zat',
        noOfComplaintResolved: 'Complaint chinfel',
        noOfMemberDeath: 'Member boral zat',
        userUID: 'User ID',
        docId: 'Report Document ID',
        createdAt: 'Report Siamni'
      }
      formRet['Report Print Hun'] = fbNow
      for (let key in keyPair) {
        if (key === 'createdAt' && formRet[key]) {
          formRet[keyPair[key]] = formRet[key].toDate()
        } else if (formRet[key]) {
          formRet[keyPair[key]] = formRet[key]
        } else if (formRet[key] === null) delete formRet[keyPair[key]]
        delete formRet[key]
      }

      return formRet
    },
    imagecheck(link) {
      return this.imageErr.includes(link)
    },
    imageError(input) {
      this.imageErr.push(input)
    },
    toDate(input) {
      return input
        ?.toDate()
        .toString()
        .replace(' GMT+0530 (India Standard Time)', '')
        .replace('GMT-0700 (Pacific Daylight Time)', '')
    },
    indexConvert(i) {
      return i.toString()
    },
    updateImageLoaded(i, bool) {
      let doc = document.getElementsByClassName('man')

      if (bool === true) {
        doc[i].innerHTML = ''
      }

      this.checkLoad[i] = !bool
    },

    checkReportApproval() {
      if (['ALF', 'SMC'].includes(this.userInfo.department)) {
        if (
          ['none', 'SHG', 'SMA'].includes(this.form.approvedBy) &&
          !['ALF'].includes(this.form.userDepartment)
        ) {
          return true
        } else return false
      }
      if (['CLF'].includes(this.userInfo.department)) {
        if (
          ['ALF', 'none'].includes(this.form.approvedBy) &&
          this.form.userDepartment != 'CLF'
        ) {
          return true
        } else return false
      }
      if (['SMMU', 'CMMU'].includes(this.userInfo.nulm)) {
        return false
      } else return false
    },
    retrieveTimestamp(obj) {
      return obj
        .toDate()
        .toString()
        .replace(' GMT+0530 (India Standard Time)', '')
        .replace('GMT-0700 (Pacific Daylight Time)', '')
    },

    async approveReport() {
      this.reportWindow = false
      this.reportOngoing = true
      this.progressMessages = 'Approving Reports'
      this.progressValue = '20'
      await firebase
        .firestore()
        .collection(this.colRep)
        .doc(this.reportApprovalId)
        .update({
          approvedBy: this.userDepartment
        })
        .then(async () => {
          this.progressMessages = 'Updating Users database'
          this.progressValue = '40'

          firebase
            .firestore()
            .collection(
              `${this.form.userNulm
                .toLowerCase()
                .replace('&', 'n')}_${this.form.userDistrict.toLowerCase()}`
            )
            .doc(this.form.userUID)
            .get()
            .then(doc => {
              let arr = doc.data().report

              arr.forEach(async e => {
                if (e.docId === this.form.docId) {
                  await firebase
                    .firestore()
                    .collection(this.colRepApp)
                    .doc(this.form.userUID)
                    .update({
                      report: firebase.firestore.FieldValue.arrayRemove(e)
                    })
                    .then(async () => {
                      let approveData = e
                      approveData.approvedBy = this.userInfo.department
                      this.progressValue = '60'
                      await firebase
                        .firestore()
                        .collection(this.colRepApp)
                        .doc(this.form.userUID)
                        .update({
                          report: firebase.firestore.FieldValue.arrayUnion(
                            approveData
                          )
                        })
                        .then(() => {
                          this.progressValue = '100'
                          this.userReportList = this.userReportList.filter(
                            e => {
                              return e.docId != this.form.docId
                            }
                          )
                          this.reportOngoing = false
                        })
                    })
                    .catch(err => {
                      this.$store.commit('changeError', err.message ?? err)
                    })
                }
              })
            })
        })
    },

    displayReport(id) {
      this.reportOngoing = true
      this.progressMessages = 'Retrieving.'
      this.progressValue = '10'
      this.checkLoad = new Array()

      this.reportApprovalId = id.docId
      this.reportCreatedAt = id.createdAt
      if (this.searchBoolRep == true) {
        this.progressMessages = 'Retrieving...'
        this.progressValue = '50'
        firebase
          .firestore()
          .collection(this.colRep)
          .doc(id.docId)
          .get()
          .then(doc => {
            this.form = doc.data()
            this.form['docId'] = doc.id
            this.checkLoad = new Array(this.form.attachment?.length)
            this.reportWindow = true
            this.progressMessages = 'Retrieving.....'
            this.progressValue = '100'
            this.reportOngoing = false
          })
      } else {
        this.reportOngoing = false

        this.form = id
        this.reportWindow = true
      }
    },
    async submitReport() {
      this.reportOngoing = true
      let ref = firebase
        .storage()
        .ref()
        .child('reports')
      ref = ref.child(this.userInfo.id)
      let newRef
      if (this.fileUpload.length > 0) {
        this.progressMessages = 'Uploading image content'
        this.fileProg = new Array(this.fileUpload.length)
        for (let [i, v] of this.fileUpload.entries()) {
          v

          let e = this.fileUpload[i]
          newRef = ref.child(
            String(firebase.firestore.Timestamp.now().seconds).concat(
              firebase.firestore.Timestamp.now().nanoseconds,
              '-',
              i,
              '.',
              e.name.toString().split('.')[1]
            )
          )

          // await new Promise((resolve) => setTimeout(resolve, 5000));
          let finalRef = newRef.put(this.fileUpload[i])
          this.fileProg[i] = 0

          finalRef.on(
            'state_changed',
            async snapshot => {
              this.fileProg[i] =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              this.progressValue = 0
              this.fileProg.forEach(e => {
                this.progressValue = e + this.progressValue
              })
              this.progressValue = Math.floor(
                this.progressValue / this.fileProg.length
              )
              if (this.progressValue === 100) {
                this.progressMessages =
                  'Finished Uploading Your Images.... Writing to database'
              }
            },
            error => {
              this.$store.commit('changeError', error)
            },
            () => {
              finalRef.snapshot.ref.getDownloadURL().then(async res => {
                this.arr.push(res)
                if (this.arr.length === this.fileUpload.length) {
                  this.submitReportFinal(this.arr)
                }
              })
            }
          )
        }
      } else {
        this.submitReportFinal(null)
      }
    },
    async submitReportFinal(attach) {
      let collection = (
        this.userDepartment +
        '_' +
        this.userDistrict
      ).toLowerCase()
      let expenditure =
        this.shelterMaintenance != null ? this.expenditure : null
      let time = firebase.firestore.Timestamp.now(),
        tanpuinaDawnna = this.tanpuinaDawnna.length ? this.tanpuinaDawnna : null
      let createdAt = new firebase.firestore.Timestamp(
        time.seconds,
        time.nanoseconds
      )
      var formInputs = {
        userUID: this.userInfo.id,
        name: this.userInfo.name,
        userNulm: this.userInfo.nulm,
        userDepartment: this.userInfo.department,
        userDistrict: this.userInfo.district,
        shelterName: this.shelterName,
        sma: this.sma,
        reportType: this.reportType,
        dateOfSubmission: this.dateOfSubmission,
        noOfCommittee: this.noOfCommittee,
        noOfMember: this.noOfMember,
        noOfStaff: this.noOfStaff,
        noOfMemberDeath: this.noOfMemberDeath,
        noOfComplaint: this.noOfComplaint,
        noOfComplaintResolved: this.noOfComplaintResolved,
        noOfMemberAttending: this.noOfMemberAttending,
        bankTotal: this.bankTotal,
        accountTotal: this.accountTotal,
        noOfMemberLend: this.noOfMemberLend,
        noLendAmount: this.noLendAmount,
        noLendPaidRec: this.noLendPaidRec,
        groupDonationAmount: this.groupDonationAmount,
        noGroupSpending: this.noGroupSpending,
        groupActivities: this.groupActivities,
        activitiesSpecify: this.activitiesSpecify,
        federationHmalakna: this.federationHmalakna,
        meetingAgenda: this.meetingAgenda,
        thurel: this.thurel,
        telpuitu: this.telpuitu,
        co: this.co,
        urp: this.urp,
        ro: this.ro,
        coVisitDate: this.coVisitDate,
        urpVisitDate: this.urpVisitDate,
        roVisitDate: this.roVisitDate,
        noOfOnM_FundRecieved: this.noOfOnMfunds,
        shelterMaintenance: this.shelterMaintenance,
        servicingCost: this.servicingCost,
        catIhomeless: this.catIhomeless,
        staffSalary: this.staffSalary,
        tanpuinaDawnna,
        noOfNulmFacility: this.noOfNulmFacility,
        noOfNulmFacilitySubmittedMonth: this.noOfNulmFacilitySubmittedMonth,
        activitiesSelect: this.activitiesSelect,
        expenditure,
        createdAt,
        approvedBy: 'none'
      }

      let data = this.clean(formInputs)
      if (this.fileUpload.length > 0) {
        formInputs['attachment'] = attach
      }

      this.progressMessages = 'Submitting your form'
      this.progressValue = '0'

      await firebase
        .firestore()
        .collection(collection)
        .add(data)
        .then(res => {
          this.progressMessages = 'Finished submitting your forms'
          this.progressValue = '50'

          let input
          let funds = this.userInfo['fundReceived']
          if (formInputs['noOfOnM_FundRecieved']) {
            if (funds) {
              funds[this.financialYear] = String(
                Number(funds[this.financialYear] ?? 0) +
                  Number(data['noOfOnM_FundRecieved'])
              )
            } else {
              funds = {
                [this.financialYear]: formInputs['noOfOnM_FundRecieved']
              }
            }

            input = {
              fundReceived: funds,
              report: firebase.firestore.FieldValue.arrayUnion({
                approvedBy: 'none',
                createdAt,
                dateOfSubmission: this.dateOfSubmission,
                docId: res.id,
                reportType: this.reportType
              })
            }
          } else {
            input = {
              report: firebase.firestore.FieldValue.arrayUnion({
                approvedBy: 'none',
                createdAt: time,
                dateOfSubmission: this.dateOfSubmission,
                docId: res.id,
                reportType: this.reportType
              })
            }
          }

          firebase
            .firestore()
            .collection(
              localStorage
                .getItem('nulm')
                .toLowerCase()
                .replace('&', 'n')
                .concat('_', localStorage.getItem('district'))
            )
            .doc(firebase.auth().currentUser.uid)
            .update(input)
            .then(
              (this.progressMessages = 'Updating your reports submitted list'),
              (this.progressValue = '70')
            )
        })
      this.progressMessages = 'Finished'
      this.progressValue = '100'
      await this.$refs.reportForm.reset()
      this.reportOngoing = false
    },
    clean(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ''
        ) {
          delete obj[propName]
        }
      }
      return obj
    },
    groupToFederation(data) {
      if (this.userDepartment == 'ALF' || this.userDepartment == 'CLF') {
        return data.replace('Group', 'Federation')
      } else {
        return data
      }
    },
    getToday() {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
      var yyyy = today.getFullYear()

      today = yyyy + '-' + mm + '-' + dd
      return today
    },
    async retrieveListOfReports() {
      this.loading = true

      let col = this.reportTypeNulmRetrieveList
        .toLowerCase()
        .concat(`_${this.reportRetrieveDistrict.toLowerCase()}`)
      this.colRep = col
      switch (this.reportTypeNulmRetrieveList) {
        case 'SHG':
        case 'ALF':
        case 'CLF':
          this.colRepApp = 'smnid'.concat(
            `_${this.reportRetrieveDistrict.toLowerCase()}`
          )
          break
        case 'SMA':
          this.colRepApp = 'suh'.concat(
            `_${this.reportRetrieveDistrict.toLowerCase()}`
          )
          break
        case 'SMC':
          this.colRepApp = 'suh'.concat(
            `_${this.reportRetrieveDistrict.toLowerCase()}`
          )
          break
        default:
          break
      }
      let nulm
      this.userReportList = []
      this.$store.commit('changeReportDistrict', this.reportRetrieveDistrict)
      if (['CLF', 'ALF', 'SHG'].includes(this.reportTypeNulmRetrieveList)) {
        nulm = 'smnid'
        this.$store.commit('changeReportNulm', 'SM&ID')
      }
      if (['SMA', 'SMC'].includes(this.reportTypeNulmRetrieveList)) {
        nulm = 'suh'
        this.$store.commit('changeReportNulm', 'SUH')
      }

      let collectionPath = nulm.concat(
        '_',
        this.reportRetrieveDistrict.toLowerCase()
      )

      if (
        this.reportTypeSearchRetrieveList == '' ||
        this.reportTypeSearchRetrieveList == null
      ) {
        this.nextBtn = true
        this.searchBoolRep = false
        let col = this.reportTypeNulmRetrieveList
          .toLowerCase()
          .concat('_', this.reportRetrieveDistrict.toLowerCase())

        await firebase
          .firestore()
          .collection(col.trim())
          .where('approvedBy', '==', this.approverInfo.trim())
          .orderBy('createdAt', 'desc')
          .limit(5)
          .get()
          .then(async doc => {
            doc.forEach(async docu => {
              let data = docu.data()
              data.docId = docu.id
              this.userReportList.push(data)
              this.lastReport = docu.data().createdAt
            })
            this.loading = false
          })
          .catch(err => {
            this.$store.commit('changeError', err), (this.loading = false)
          })
      } else {
        this.searchBoolRep = true
        this.nextBtn = false
        let searchInd = this.reportTypeSearchRetrieveList
        let searchBy = this.reportTypeSearchByRetrieveList.toLowerCase()
        if (searchBy === 'group code') {
          searchBy = 'code'
        } else {
          searchInd = searchInd.toUpperCase()
        }

        await firebase
          .firestore()
          .collection(collectionPath)
          .where(searchBy, '==', searchInd)
          .where('department', '==', this.reportTypeNulmRetrieveList)
          .get()
          .then(async doc => {
            if (doc.empty) {
              this.$store.commit('changeError', 'No user Found')
            }

            doc.forEach(async e => {
              let dataReport = e.data().report

              this.userReportListDetails = e.data()
              this.userReportListDetails.userUID = e.id

              if (dataReport)
                dataReport.forEach(e => {
                  this.userReportList.push(e)
                })
              else {
                this.$store.commit('changeError', 'No Report Found')
              }
            })
          })
          .catch(err => this.$store.commit('changeError', err))
          .finally((this.loading = false))
      }
    },

    async retrieveListOfReportsNext() {
      this.userReportList = []
      this.loading = true
      let col = this.reportTypeNulmRetrieveList
        .toLowerCase()
        .concat('_', this.reportRetrieveDistrict.toLowerCase())
      await firebase
        .firestore()
        .collection(col)
        .where('approvedBy', '==', this.approverInfo)
        .orderBy('createdAt', 'desc')
        .startAfter(this.lastReport)
        .limit('5')
        .get()
        .then(
          async doc =>
            doc.forEach(async docu => {
              this.userReportList.push(docu.data())
              this.lastReport = docu.data().createdAt
            }),
          (this.loading = false)
        )
        .finally((this.loading = false))
    },
    fundRec(input) {
      let funds = input.fundReceived

      if (funds) {
        if (funds[this.financialYear]) return funds[this.financialYear] ?? 0
      } else return 0
    }
  },
  computed: {
    calculateWidth() {
      if (this.$vuetify.breakpoint.mobike) return '90vw'
      else return '30vw'
    },
    checkLoader() {
      return this.checkLoad
    },
    expenditureRep() {
      let staffSalary = isNaN(parseInt(this.form.staffSalary))
        ? 0
        : parseInt(this.form.staffSalary)
      let catIhomeless = isNaN(parseInt(this.form.catIhomeless))
        ? 0
        : parseInt(this.form.catIhomeless)
      let servicingCost = isNaN(parseInt(this.form.servicingCost))
        ? 0
        : parseInt(this.form.servicingCost)
      let shelterMaintenance = isNaN(parseInt(this.form.shelterMaintenance))
        ? 0
        : parseInt(this.form.shelterMaintenance)
      return staffSalary + catIhomeless + servicingCost + shelterMaintenance
    },
    fundRecRep() {
      return null
    },
    financialYearRep() {
      if (this.form.dateOfSubmission != null) {
        let month = parseInt(this.form.dateOfSubmission.split('-')[0])
        let year = parseInt(this.form.dateOfSubmission.split('-')[1])
        if (month < 4) {
          return year - 1
        } else return year
      } else return null
    },
    reportCheckRefurbOnly() {
      if (this.form.tanpuinaDawnna) {
        if (this.form.tanpuinaDawnna.indexOf('Refurbishment') > -1) return true
        else return false
      } else return false
    },
    reportCheckOnM() {
      if (this.form.tanpuinaDawnna) {
        if (this.form.tanpuinaDawnna.indexOf('O&M') > -1) return true
        else return false
      } else return false
    },
    // retrieveReportsLists() {
    //   if (this.reportTypeNulmRetrieveList === "SMA") {
    //     return ["Monthly Report"];
    //   }
    //   else return [""];
    // },
    retrieveReports() {
      if (
        this.userInfo.department === 'SMA' ||
        this.userInfo.department === 'SHG'
      ) {
        return [this.userInfo.department]
      }
      if (this.userInfo.department === 'ALF') {
        return ['SHG']
      }
      if (this.userInfo.department === 'CLF') {
        return ['CLF', 'ALF', 'SHG']
      }
      if (this.userInfo.department === 'SMC') {
        return ['SMC', 'SMA']
      }
      if (this.userInfo.nulm === 'CMMU' || this.userInfo.nulm === 'SMMU') {
        return ['SHG', 'ALF', 'CLF', 'SMA', 'SMC']
      } else return []
    },
    approver() {
      if (this.userInfo.department === 'ALF') {
        return ['ALF', 'none']
      } else if (this.userInfo.department === 'CLF') {
        return ['CLF', 'ALF', 'none']
      } else if (this.userInfo.department === 'SMC') {
        return ['SMC', 'none']
      } else if (
        this.userInfo.nulm === 'SMMU' ||
        this.userInfo.nulm === 'CMMU'
      ) {
        return ['ALF', 'CLF', 'SMA', 'none']
      } else return ['none']
    },
    urpVisitDate: {
      get() {
        if (this.urp_date) {
          return this.urp_date
            .split('-')[2]
            .concat(
              '-',
              this.urp_date.split('-')[1],
              '-',
              this.urp_date.split('-')[0]
            )
        } else return null
      },
      set() {
        this.urp_date = null
      }
    },
    roVisitDate: {
      get() {
        if (this.ro_date) {
          return this.ro_date
            .split('-')[2]
            .concat(
              '-',
              this.ro_date.split('-')[1],
              '-',
              this.ro_date.split('-')[0]
            )
        } else return null
      },
      set() {
        this.ro_date = null
      }
    },
    coVisitDate: {
      get() {
        if (this.co_date) {
          return this.co_date
            .split('-')[2]
            .concat(
              '-',
              this.co_date.split('-')[1],
              '-',
              this.co_date.split('-')[0]
            )
        } else return null
      },
      set() {
        this.co_date = null
      }
    },
    expenditure: {
      get() {
        let staffSalary = isNaN(parseInt(this.staffSalary))
          ? 0
          : parseInt(this.staffSalary)
        let catIhomeless = isNaN(parseInt(this.catIhomeless))
          ? 0
          : parseInt(this.catIhomeless)
        let servicingCost = isNaN(parseInt(this.servicingCost))
          ? 0
          : parseInt(this.servicingCost)
        let shelterMaintenance = isNaN(parseInt(this.shelterMaintenance))
          ? 0
          : parseInt(this.shelterMaintenance)
        return staffSalary + catIhomeless + servicingCost + shelterMaintenance
      },
      set() {}
    },
    financialYearItems: {
      get() {
        let items = []
        let data
        let object = this.userInfo?.fundReceived
        for (let property in object) {
          data = {
            year: property,
            amount: object[property]
          }
          items.push(data)
        }

        return items
      }
    },
    dateOfSubmission: {
      get() {
        try {
          if (this.reportType === 'Monthly Report') {
            return this.dos.split('-')[1].concat('-', this.dos.split('-')[0])
          } else
            return this.dos
              .split('-')[2]
              .concat('-', this.dos.split('-')[1], '-', this.dos.split('-')[0])
        } catch {
          return null
        }
      },
      set() {
        this.dos = this.getToday()
      }
    },
    dateOfSubmissionReportList: {
      get() {
        try {
          if (this.reportTypeRetrieve === 'Monthly Report') {
            return this.dosReportList
              .split('-')[1]
              .concat('-', this.dosReportList.split('-')[0])
          } else
            return this.dosReportList
              .split('-')[2]
              .concat(
                '-',
                this.dosReportList.split('-')[1],
                '-',
                this.dosReportList.split('-')[0]
              )
        } catch {
          return null
        }
      },
      set() {
        this.dos = this.getToday()
      }
    },

    financialYear: {
      get() {
        try {
          if (this.dateOfSubmission != null) {
            let month = parseInt(this.dateOfSubmission.split('-')[0])
            let year = parseInt(this.dateOfSubmission.split('-')[1])
            if (month < 4) {
              return year - 1
            } else return year
          } else return null
        } catch {
          return '2021'
        }
      },
      set() {}
    },

    reportTypeLabel() {
      if (this.reportType) {
        if (this.reportType === 'Monthly Report') {
          return `Report for the month`
        } else {
          return 'Report Submission date'
        }
      } else {
        return null
      }
    },
    datePickerType() {
      if (this.reportType) {
        if (this.reportType === 'Monthly Report') {
          return 'month'
        } else return 'date'
      } else {
        return 'date'
      }
    },
    reportListType() {
      if (this.userNulm === 'SM&ID' && this.userDepartment === 'SHG') {
        return [
          'Monthly Report',
          'Meeting Report',
          'SHG enkawltu CO, URP leh RO te tlawh dan'
        ]
      }
      if (
        this.userDepartment === 'CLF' ||
        this.userDepartment === 'ALF' ||
        this.userDepartment === 'SMA' ||
        this.userDepartment === 'SMC'
      ) {
        return ['Monthly Report', 'Meeting Report']
      } else return []
    },
    ...mapState(['userInfo'])
  },
  watch: {
    tanpuinaDawnna() {
      let inlet = []
      inlet = [...this.tanpuinaDawnna]
      if (inlet.length > 0) {
        if (
          inlet.length === 1 &&
          this.tanpuinaDawnna.includes('Refurbishment')
        ) {
          this.showTd = 1
        } else if (this.tanpuinaDawnna.includes('O&M')) {
          this.showTd = 2
        }
      } else this.showTd = 0
    },

    reportType() {
      let tempType = this.reportType
      let dateOfSubmission = this.dateOfSubmission
      this.$refs.reportForm.reset()
      this.reportType = tempType
      this.dateOfSubmission = dateOfSubmission
    }
  },
  created() {
    if (
      ['SHG', 'SMA', 'CLF', 'ALF', 'SMC'].includes(this.userInfo.department)
    ) {
      this.reportTypeSearchByRetrieveList = this.userInfo.name
      this.colRep = this.userInfo.department
        .toLowerCase()
        .concat(`_${this.userInfo.district.toLowerCase()}`)
      this.userDepartment = this.userInfo.department

      this.userNulm = this.userInfo.nulm
      this.userDistrict = this.userInfo.district
      this.reportTypeNulmRetrieveList = this.userDepartment
      this.reportRetrieveDistrict = this.userDistrict

      if (
        ['SHG', 'SMA', 'ESTP', 'SEP', 'SUSV'].includes(this.userInfo.department)
      ) {
        this.searchBoolRep = true
        this.reportTypeSearchRetrieveList = this.userInfo.name
      }
      this.reportTypeSearchByRetrieveList = 'Name'
      this.userReportList = this.userInfo.report
      this.userReportListDetails = this.userInfo
      this.$store.commit('changeReportNulm', this.userInfo.nulm)
      this.$store.commit('changeReportDistrict', this.userInfo.district)
    } else if (['SMMU', 'CMMU'].includes(this.userInfo.nulm)) {
      this.reportRetrieveDistrict = this.userInfo.district
    }
  },
  mounted() {}
}
</script>
<style scoped>
.tableData {
  box-sizing: border-box;
  border: 1px solid rgba(128, 128, 128, 0.37);
  border-radius: 2px;
}
.buttonGlass {
  box-sizing: border-box;
  overflow: hidden;
}
.buttonGlass::before {
  position: absolute;
  left: 0%;
  width: 20%;
  backdrop-filter: blur(2px);
  transform: skewX(-40deg) translateX(-3rem);
  background-color: white;
  opacity: 0.4 !important;
  z-index: 2;
  transition: 0.4s ease-out;
  border-radius: none !important;
}
.buttonGlass:hover::before {
  left: 100%;
  transform: skewX(-40deg) translateX(1rem);
}
.formBorder {
  border: 1px solid #7f7f7f;
  margin-bottom: 2rem;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.bgSvg {
  min-height: 80vh;

  background-color: var(--v-secondary-darken--20);
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='111' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23cacaca' fill-opacity='0.46'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.46'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.bgSvgDark {
  min-height: 80vh;

  background-color: #07070748;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23060606' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.reportCard {
  min-height: 100vh;
}
.backDropper {
  min-height: 100vh;

  backdrop-filter: blur(15px);
}
</style>
